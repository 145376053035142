@keyframes loadInBot { 
    0% {
      opacity: 0;
      transform: scale(0.87);
    }

    20% {
        opacity: 1;
      }

      90% {
        opacity: 1;
      }
  
    100% {
      transform: scale(1);
      opacity: 0;
    }
  }


.loadbot_png{
    margin-left: auto;
    margin-right: auto;
    width: 70%;
    max-width: 450px;
    animation: loadInBot 2.5s linear;
}

.landing {
    display: flex;
    flex-direction: column;
}

@keyframes FadeInPopUpBot { 
    0% {
      opacity: 0;
      transform: scale(0.7);
      transform: translateY(20%);
    }
  
    85% {
      opacity: 1;
      transform: scale(1.1);
    }
    100% {
      transform: scale(1);
      transform: translateY(0%);
    }
  }

.hoverPersona:nth-child(1) { animation-delay: 0.1s }
.hoverPersona:nth-child(2) { animation-delay: 0.2s }
.hoverPersona:nth-child(3) { animation-delay: 0.3s }
.hoverPersona:nth-child(4) { animation-delay: 0.4s }
.hoverPersona:nth-child(5) { animation-delay: 0.5s }
.hoverPersona:nth-child(6) { animation-delay: 0.6s }


.hoverPersona{
    font-weight: 400;
    text-align: center;
    line-height: 60px;
    font-size: 23px;
    letter-spacing: 0.04em;
    animation: FadeInPopUpBot 0.2s linear;
    animation-fill-mode: backwards;
}

.landingAnimation {
    /* This section calls the slideInFromLeft animation we defined above */
    animation: 1s ease-out 0s 1 fadein;
}

.loadingBlank{
    display: flex;
    align-items: center;
    margin: auto;
    position: fixed;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-color: #F7EEEC;
    z-index: 300;
}



.topSection{
    margin-top: 17vh;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
}

.botWrapper{
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
}




.hoverPersona:hover{
    transform: scale(1.1, 1.1);
}

.topText{
    display: flex;
    font-weight: 500;
    font-size: calc(0.8em + 1.3vw);
    line-height: calc(1.8em + 1.5vw);
    text-align: center;
    letter-spacing: 0.04em;
    color: rgba(23, 23, 23);
    padding: 6px;
    justify-content: center;
}

.toplogo{
    display: flex;
    margin-left: auto;
    margin-right: auto;
    justify-items: center;
    align-items: center;
    max-width: 450px;
    padding-left: 50px;
    padding-right: 50px;
    padding-bottom: 20px;
}


.circleButtonBig{
  position: absolute;
  top: 30px;
    right: 30px;
    display: flex;
    align-items: center;
    justify-content:center;
    margin-right: 14px;
    height: 45px;
    width: 45px;
    background-color: #F6D9D4;
    border: 1px solid #000000;
    border-radius: 50%;
    box-shadow: 1.5px 1.5px 0px #000000;
    z-index:30;
}

.circleButtonBig:hover {
    transform: translate(1px,1px);
    box-shadow: 0.5px 0.5px 0px #000000;
  }

.circleButtonBig:active {
    background-color:#EA808D;
  }


  @media only screen and (max-width: 700px) {

    .topText{
        font-size: 19px;
        line-height: 30px;
        padding-left: 10px;
        padding-right: 10px;
        padding-bottom: 20px;
    }



    .topSection{
        margin-top: 70px;
        margin-bottom: 10px;
      }
    .toplogo{
        display: flex;
        margin-left:auto;
        margin-right:auto;
        max-width: 230px;
        padding-left: 50px;
        padding-right: 50px;
    }

    .circleButtonBig{
        position: absolute;
        top: 10px;
        right: 10px;
        display: flex;
        align-items: center;
        justify-content:center;
        height: 35px;
        width: 35px;
        border: 1px solid #000000;
        border-radius: 50%;
        box-shadow: 1px 1px 0px #000000;
    }

    .hoverPersona{
        font-weight: 400;
        text-align: center;
        line-height: 60px;
        font-size: 19px;
        letter-spacing: 0.04em;
    }
  }
