body {
  background-color: #F7EEEC;
  height: 100vh;
  margin: auto;
  max-width: 95%;
  font-family: 'Darker Grotesque', sans-serif;
}

@keyframes fadeinBottom {
  0% {
    transform: translateY(2%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes fadeinLeft {
  0% {
    transform: translateX(-2%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes fadeinRight {
  0% {
    transform: translateX(2%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes fadein {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.backtoTop{
  position: fixed;
  left: 25px;
  padding-top: 0px;
  padding-bottom: 30px;
  padding-right: 25px;
  padding-left: 20px;
  top: 50%;

}
.backtoTop:hover {
  transform: translate(1.5px,1.5px);
  filter: invert(27%) sepia(51%) saturate(178%) hue-rotate(6deg) brightness(144%) contrast(97%);
}

.backtoTop:active {
}

a, a:hover, a:focus, a:active {
  text-decoration: none;
  color: inherit;
}

@font-face {
  font-family: 'VG5000';
  src: local('VG5000'), url(./fonts/VG5000_Regular_web.ttf) format('truetype');
}

@media (min-width: 501px) and (max-width: 1450px) {
  .backtoTop {
    position: absolute;
    top: 85pt;
  } 
}

@media (min-width: 1px) and (max-width: 500px) {
  .backtoTop {
    position: absolute;
    top: 85pt;
    left: 10px;
  } 
  .backtoTop.aboutTop{
    position: absolute;
    left: 7px;
    padding-top: 0px;
    padding-bottom: 30px;
    padding-right: 25px;
    padding-left: 20px;
    top: 18px;
  }

}