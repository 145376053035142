
@keyframes FadeInPopUp { 
    0% {
      opacity: 0;
      transform: scale(0.7);
    }
  
    85% {
      opacity: 1;
      transform: scale(1.02);
    }
    100% {
      transform: scale(1);
    }
  }

.videoContainer:nth-child(1) { animation-delay: 0.1s }
.videoContainer:nth-child(2) { animation-delay: 0.2s }
.videoContainer:nth-child(3) { animation-delay: 0.3s }
.videoContainer:nth-child(4) { animation-delay: 0.4s }
.videoContainer:nth-child(5) { animation-delay: 0.5s }
.videoContainer:nth-child(6) { animation-delay: 0.6s }
.videoContainer:nth-child(7) { animation-delay: 0.7s }
.videoContainer:nth-child(8) { animation-delay: 0.8s }

.videoContainer {
    display: flex;
    flex-direction: column;
    width: 290px;
    padding: 13px;
    animation: FadeInPopUp 0.2s ease-out;
    animation-fill-mode: backwards;
}

.videoContainer:hover{
    transform: scale(0.96);
}

.infoContainer {
    margin-top: 12px;
    display: flex;
    flex-direction: row;
}

.thumbnailContainer {
    display: block;
    width: 100%;
    height: auto;
    position: relative;
    overflow: hidden;
    padding: 56.25% 0 0 0; /* 34.37% = 100 / (w / h) = 100 / (640 / 220) */}

.thumbnail {
    display: block;
    max-width: 100%;
    /* max-height: 100%; */
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

.iconContainer {
    margin-right: 12px;
}

.titlesContainer {
    padding-right: 24px;
}

.titleContainer {
    padding-bottom: 7px;
}

.numberContainer {
    display: flex;
    flex-direction: row;
}



/* For small tweaks on Youtube video */



.channel_icon {
    height: 50px;
    width: 50px;
    border-radius: 50%;
    object-fit: contain;
}

.title {
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    max-height: 44px;
    text-overflow: ellipsis;
    overflow: hidden;
    color: rgb(3, 3, 3);
}

.small_text {
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    color: rgb(96, 96, 96);
}


@media (min-width: 1157px) and (max-width: 1332px) {
    .videoContainer {
        width: 340px;
    } 
  }
  @media (min-width: 1001px) and (max-width: 1156px) {
    .videoContainer {
        width: 290px;
    } 
  }
  @media (min-width: 775px) and (max-width: 1000px) {
    .videoContainer {
        width: 340px;
    } 
  }
  @media (min-width: 100px) and (max-width: 775px) {
    .videoContainer {
        width: 100%;
    } 
  }