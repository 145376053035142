.aboutFade {
    /* This section calls the slideInFromLeft animation we defined above */
    animation: 1s ease-out 0s 1 fadein;
}


.about{
    display: flex;
    flex-direction: column;
    margin: auto;
    max-width: 1000px;
    width: 90%;
    padding: 30px;
}

.circleButtonBig.Twitter{
    justify-items: center;
    align-items: center;
    top: 8px;

}

.twitter_logo{
    padding-top: 5px;
    opacity: 0.8;
}


.VideoContainers{
text-align: center;
animation: 2s ease-out 1 fadein;
}

.VideoContainer{
    display: inline-block;
    padding-top:50px;
    padding-bottom:0px;
    margin: auto;   
    width: 600px;
    height: 600px;
}

.textVideoContainer{
    display: flex;
    flex-direction: row;
}

.textContainer{
    width: 700px;
    padding-right: 40px;
    padding-bottom: 40px;
}
.gifContainer{
    width: 600px;
}

.scrapeGif{
    width: 100%;
}


.link{
    color: black;
    text-decoration: underline;
    text-underline-position: under;
    line-height: 20px;
    padding-top: 0px;
}
.link:hover{
    color: rgb(158, 17, 17);
    text-decoration: underline;
}

.supportlogo{
    padding-top: 30px;
    padding-right: 40px;
}


.Question{
    font-size: 35px;
    font-weight: 500;
    line-height: 55px;
    letter-spacing: 0.03em;
    padding-bottom: 20px;
    padding-top: 50px;
}

.Answer{
    font-size: 24px;
    font-weight: 300;
    line-height: 33px;
    letter-spacing: 0.03em;
}

.toplogoabout{
    display: flex;
    margin-top: 30px;
    margin-left: auto;
    margin-right: auto;
    width: 300px;
    padding-bottom: 20px;
}

.aboutCharacters{
    display: flex;
    margin-top: 20px;
    margin-left: auto;
    margin-right: auto;
    padding-right:30px;
    padding-left:30px;
    width: 100%;
    height: auto;
    /* Magic! */
    max-width: 40vw;
    min-width: 260px;
    padding-bottom: 20px;
}

.topTextabout{
    font-weight: 500;
    text-align: center;
    font-size: 26px;
    letter-spacing: 0.04em;
    color: rgba(23, 23, 23);
    padding: 10px;
    display: none;
    justify-content: center;
    margin-bottom: 50px;
}

@media (max-width: 900px) {


    .textVideoContainer{
        flex-direction: column;
    }
    .textContainer{
        width: 100%;
    }
    .gifContainer{
        width: 100%;
    }

    
    .twitter_logo{
        width: 18px;
        height:18px;
        padding-top: 5px;
        opacity: 0.8;
    }
    .Answer{
   font-size: 19px; 
   line-height: 28px;
   letter-spacing: 0.05em;

    }
    .Question{
        font-size: 22px; 
        line-height: 30px;
        letter-spacing: 0.04em;

    }
    a.link{
        font-size: 19px; 
        line-height: 28px; 
    }
    .VideoContainer{
        padding-top:25px;
        padding-bottom:20px;
        margin: auto;
        width: 87vw;
        height: 87vw;
    }

}
