.personaAll {
      /* This section calls the slideInFromLeft animation we defined above */
      animation: 0.4s ease-out 0s 1 fadein;
      pointer-events: auto;
    }

.allContainer {
    background-color: #f8f8f8; 
    font-family: 'Roboto', Arial;
    display: flex;
    margin: auto;
    flex-direction: column;
    padding-bottom: 10px;
    margin-bottom: 50px;
    max-width: 1300px;
    /* -webkit-box-shadow: 7px 7px 21px -8px rgba(173, 173, 173, 1);
    -moz-box-shadow: 7px 7px 21px -8px rgba(173, 173, 173, 1);
    box-shadow: 7px 7px 21px -8px rgba(173, 173, 173, 1); */

    border: 1px solid #000000;
    box-sizing: border-box;
    /* box-shadow: 8px 8px 0px rgba(83, 77, 77, 0.26); */
    border-radius: 15px 15px 15px 15px;
}

.topbar {
    display: flex;
    box-sizing: border-box;
    align-items: center;
    vertical-align: baseline;

    position: relative;
    border-radius: 15px 15px 0px 0px;
    height: 42px;
    top: 0;
    background-color: #EFF1F4;
    border-bottom: 1px solid #000000; 
}

.tab_bar{
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: -1px;
    left: 146px;
    box-sizing: border-box;
    border-radius: 20px 20px 0px 0px;
    height: 36.5px;
    width: 245px;
    background-color: #F7F7F7;
    border-top: 1px solid #000000;
    border-left: 1px solid #000000;
    border-right: 1px solid #000000;
    border-bottom: 1px solid #F7F7F7;
}

.vertical_line {
    margin-left: 28px;
    border-left: 1px solid #000000;
    height: 42px;
  }

.front_back{
    display: flex;
    margin-left: 34px;
    align-items: center;
}

.history_block{
    display: flex;
    flex-direction: row;
    align-items: center;
    position: absolute;
    right:16px;
}

.retrievedOn{
    display: flex;
    font-family: "Darker Grotesque";
    font-weight: 500;
    font-size: 18px;
    color: black;
    opacity: 0.9;
    letter-spacing:0.06em;
    padding-bottom: 1px;
    margin-right: 8px;
}

.date_text{
display: flex;
 font-family: "Darker Grotesque";
 font-weight: 500;
 font-size: 18px;
 color: black;
 opacity: 0.9;
 letter-spacing:0.06em;
 padding-bottom: 1px;
}

.time_text{
    display: flex;
    font-family: "Darker Grotesque";
    font-weight: 500;
    font-size: 18px;
    color: black;
    opacity: 0.9;
    letter-spacing:0.06em;
    padding-bottom: 1px;
    margin-left: 3px;
}

.history_text{
    font-family: "Darker Grotesque";
    font-weight: 500;
    font-size: 18px;
    color: black;
    opacity: 0.9;
    letter-spacing:0.06em;
    padding-bottom: 5px;
    margin-right: 7px;
}

.circleButton{
    display: flex;
    align-items: center;
    justify-content:center;
    margin-right: 15px;
    height: 26px;
    width: 26px;
    background-color: #D5DBE3;
    border: 1px solid #000000;
    border-radius: 50%;
    box-shadow: 1px 1px 0px #000000;
}

.circleButton:hover {
    transform: translate(1px,1px);
    box-shadow: 0px 0px 0px #000000;
  }

.circleButton:active {
    background-color:#939393;
  }

.youtubeBar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: #ffffff;
}

.leftlogo_pic{
    padding-top: 5px;
}


.videosContainerCentering{
    width: 100%;
    display: flex;
    justify-content: center;
}

.videosContainer {
    background-color: #f8f8f8;
    display: flex;
    flex-wrap:wrap;
    flex-direction: row;
    justify-content: center;
}




.retrievedDate {
    font-weight: 500;
    font-size: 23px;
    line-height: 29px;
    letter-spacing: 0.08em;
    color: rgba(23, 23, 23);
    padding-bottom: 30px;
    text-align: center;
}

.personaInfo {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    font-family: 'Darker Grotesque', sans-serif;
    justify-content: center;
    align-items:stretch;
    padding-bottom: 20px;
    padding-top: 25px;
}

.box_bot {
    display: flex;
    flex-direction: column;
    overflow: visible;
    /* transform: translate3d(0,130px,0); */
    flex-grow: 1;
    max-width: 180px;
    padding-right: 5px;
}

.box_title {
    display: flex;
    flex-direction: column;
    flex-grow: 2;
    padding: 20px;
    max-width: 700px;
}

.box_trained {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding: 20px;
    max-width: 250px; 
}

.mainHead {
    font-weight: 500;
    font-size: 32px;
    line-height: 15px;
    letter-spacing: 0.04em;
    color: rgba(23, 23, 23);
    padding-bottom: 30px;
}

.subHead {
    font-weight: 500;
    font-size: 23px;
    line-height: 15px;
    letter-spacing: 0.04em;
    color: rgba(23, 23, 23);
    padding-bottom: 30px;
}

.link {
    padding-top: 20px;
    text-decoration: underline;
    font-weight: 500;
    font-size: 23px;
    line-height: 30px;
    letter-spacing: 0.06em;
    color: rgba(43, 43, 43, 0.7);
}

.text {
    font-size: 19px;
    line-height: 30px;
    letter-spacing: 0.05em;
    color: rgba(43, 43, 43, 1);
}


@media (min-width: 1157px) and (max-width: 1332px) {
    .box_title {
        max-width: 500px;    
    }
  }
  @media (min-width: 1001px) and (max-width: 1156px) {
    .box_title {
        max-width: 390px;    
    }
  }
  @media (min-width: 1px) and (max-width: 1000px) {

    .mainHead {
        text-align: center;
    }
    .subHead {
        text-align: center;
    }
    .box_bot{
        max-width: 100%; 
        align-items: center;   
    }
    .box_title {
        max-width: 100%;    
        padding-left: 8%;
        padding-right: 8%;
    }
    .box_trained {
        max-width: 100%;    
        padding-left: 8%;
        padding-right: 8%;}
    .searchbar_pic{
        display: none;
    }
    .rightitem_pic{
        display: none;
    }
    .date_text{
        margin-left: 10px;
         font-family: "Darker Grotesque";
         font-weight: 500;
         font-size: 19px;
         letter-spacing:0.04em;
        }
    .history_text{
        display: none;
    }
    .tab_bar{
        width: 150px;
        left: 107px;
    }
    .front_back{
        margin-left: 21px;
    }
    .retrievedOn{
        display: none;
    }
    .circleButton.historyButton{
        margin-right: -4px;
    }

        
  }
